


import React, { useState } from 'react';
import './Home3.css';
import Title from '../Title/Title';
import white_arrow from '../../assets/gallery/white-arrow.png';


import gallery_1 from '../../assets/dennis-images/imagr-3.jpg';

import gallery_01 from '../../assets/dennis-images/imagr-3.jpg';
import gallery_02 from '../../assets/dennis-images/imagr-3.jpg';
import gallery_03 from '../../assets/dennis-images/imagr-3.jpg';
import gallery_04 from '../../assets/dennis-images/imagr-3.jpg';
// import gallery_04 from '../../assets/dennis-images/imagr-3.jpg';
// import gallery_06 from '../../assets/dennis-images/imagr-3.jpg';

import gallery_11 from '../../assets/dennis-images/imagr-3.jpg';
import gallery_12 from '../../assets/dennis-images/imagr-3.jpg';
import gallery_13 from '../../assets/dennis-images/imagr-3.jpg';
import gallery_14 from '../../assets/dennis-images/imagr-3.jpg';

import gallery_21 from '../../assets/dennis-images/imagr-3.jpg';
import gallery_22 from '../../assets/dennis-images/imagr-3.jpg';
import gallery_23 from '../../assets/dennis-images/imagr-3.jpg';
import gallery_24 from '../../assets/dennis-images/imagr-3.jpg';


const Home3 = () => {
  const [seeMore, setSeeMore] = useState(false);
  const buttonTitle = seeMore ? "Show less pictures" : "Show more pictures";

  return (
    <div className=''>
      {/* <div className=""> */}
        <Title title={"Programs"}/>
      {/* </div> */}
      <div className="gallery">
        <figure>
          <img src={gallery_01} alt="Description for Image 1" />
          <figcaption>Provides computers and computer services</figcaption>
        </figure>
        <figure>
          <img src={gallery_02} alt="Description for Image 2" />
          <figcaption>Provides books and other souvenirs</figcaption>
        </figure>
        <figure>
          <img src={gallery_03} alt="Description for Image 3" />
          <figcaption>Provides books and other souvenirs</figcaption>
        </figure>
        <figure>
          <img src={gallery_04} alt="Description for Image 4" />
          <figcaption>Excited kids</figcaption>
        </figure>
      </div>

      {seeMore && (
        <>
          <div className="gallery">
            <figure>
              <img src={gallery_11} alt="Description for Image 5" />
              <figcaption>Provides computers and computer services</figcaption>
            </figure>
            <figure>
              <img src={gallery_12} alt="Description for Image 6" />
              <figcaption>Classroom experience</figcaption>
            </figure>
            <figure>
              <img src={gallery_13} alt="Description for Image 7" />
              <figcaption>Traditional way of studying</figcaption>
            </figure>
            <figure>
              <img src={gallery_14} alt="Description for Image 8" />
              <figcaption>Provides books and other souvenirs</figcaption>
            </figure>
          </div>
          <div className="gallery">
            <figure>
              <img src={gallery_21} alt="Description for Image 9" />
              <figcaption>Excited students in the classroom</figcaption>
            </figure>
            <figure>
              <img src={gallery_22} alt="Description for Image 10" />
              <figcaption>Excited students in the classroom</figcaption>
            </figure>
            <figure>
              <img src={gallery_23} alt="Description for Image 11" />
              <figcaption>Encouraging the young students</figcaption>
            </figure>
            <figure>
              <img src={gallery_24} alt="Description for Image 12" />
              <figcaption>Sports events </figcaption>
            </figure>
          </div>
        </>
      )}

      <button onClick={() => { setSeeMore(!seeMore) }} className='btn dark-btn more'>
        {buttonTitle}
        {/* <img src={white_arrow} alt="" /> */}
      </button>
    </div>
  );
};

export default Home3;
