import React from 'react'
import "./AboutUs.css"


import profile_gifty_1 from "../../assets/aboutus/gifty.png"
import profile_peter_2 from "../../assets/aboutus/peter.png"
import program_3 from "../../assets/publication/abst3.png"


import program_icon_1 from "../../assets/publication/program-icon-1.png"
import program_icon_2 from "../../assets/publication/program-icon-2.png"
import program_icon_3 from "../../assets/publication/program-icon-3.png"


const AboutUs1 = () => {
  return (
    <div className='programs'>

        <div className="program">
            {/* <a href="https://pubs.acs.org/doi/full/10.1021/acsomega.8b01878" target='_blank'> */}
                <img src={profile_gifty_1} alt="" />
                <div className='description'>
                    {/* <h3>ACS Omega, 2018, 3</h3> */}
                    <h2>Gifty Tutu Dzakpasu</h2>
                    <h4>Registered Nurse - United Kingdom</h4>
                    {/* <p>
                    An optimistic, enthusiastic, and knowledgeable registered general nurse in the United Kingdom with 
                    hands-on work experience and commitment to helping others, especially young students. 
                    Gifty and Peter decided to launch Gifty-Peter Foundation, a scholarship and 
                    support program, to help the students of Weta community. As Gifty is committed to 
                    safeguarding and promoting the welfare of people, she wants to harness these set of 
                    skills to help build and promote the educational system of her community. 
                    </p> */}
                </div>
                <div className="caption">
                    {/* <img src={program_icon_1} alt="" /> */}
                    <p>Gifty Tutu Dzakpasu</p>
                </div>
            {/* </a> */}
        </div>


        <div className="program">
            {/* <a href="https://www.sciencedirect.com/science/article/abs/pii/S003960282030248X#fig0012" target='_blank'> */}
             
                <div className='description'>
                    <p>
                        Motivated by a vision to enhance the educational system and help improve students' 
                        performances in Ghana. Peter co-founded Gifty-Peter Foundation alongside Gifty Tutu Dzakpasu. 
                        Together, they strive to make a meaningful impact on the region's youth and educational system. 
                        With a Ph.D. in Chemistry and a solid foundation in Computer Science, he brings a wealth of 
                        expertise to his role as a co-founder. Peter utilizes his academic background and practical 
                        experience to craft robust, scalable, and user-centric software solutions. His unwavering 
                        commitment to excellence and relentless pursuit of technological innovation shine 
                        through in every project he tackles.
                    </p>
                </div>
                <div className="caption">
                    {/* <img src={program_icon_2} alt="" /> */}
                    <p>Dr. Peter Zorve (Ph.D.)</p>
                </div>
            {/* </a> */}
        </div>



    </div>
  )
}

export default AboutUs1






