import React from 'react'
import "./Home1.css"
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
// import img_1 from "../../assets/img/hero_img.png"
import img_1 from "../../assets/logo/logo-3.png"
// import image_1_students from "../../assets/home/image-1-students.jpg"
import image_1_students from "../../assets/dennis-images/imagr-7-removebg-preview.png"
import image_2_students from "../../assets/home/image-2-students.jpg"
import Title from '../Title/Title';


const Home1 = () => {
    return (
      <div className="hero--section">
        <div className="hero--section--content--box">
          <div className="hero--section--content">
            <p className="section--title">Profile</p>

            <p className="hero--section-description">


            I am a tutor of Biology and Mathematics with nearly 15 years of teaching experience under my belt. 
            The ability to concisely explain theoretical principles and concepts to unveil the practicality buried in it in order 
            to deepen the understanding of my students is my greatest asset. My commitment to fostering a rich learning environment 
            and advancing knowledge continues to drive my professional endeavours. My journey in education has been fueled by a 
            deep-rooted passion to inspire Christ-like integrity into young people as future leaders. Currently, I am dedicated to 
            teaching both O-level and A-level Cambridge Biology, as well as West African Senior Secondary Certificate Examination 
            (WASSCE Biology) at Ghana Christian International High School (GCIHS) 
            I double as a science researcher with at least a co-authored publication under my sleeve. I have a proven track record as a 
            professional with high levels of administrative skills and a firm sense of responsibility. I also coach the Mathematics 
            and Science Olympiad team, striving to inspire and develop the next generation of problem-solvers and innovators.

            



              <br /> 
              By equipping students with these essential skills, we hope to not only improve their academic performance but also inspire a lifelong love for learning and self-improvement. 
              <br />
            </p>
          </div>

          {/* <div className="btn-container">
            <Link to="/aboutus">
              <button className="btn  dark-btn">About us</button>
            </Link>
            <Link to="/projects">
              <button className="btn  dark-btn">Projects</button>
            </Link>
            <Link to="/contact">
              <button className="btn  dark-btn">Contact us</button>
            </Link>
          </div> */}

        </div>
        <div className="hero--section--img">
          <img src={image_1_students} alt="Hero Section" />
        </div>
      </div>
    );
  }

export default Home1