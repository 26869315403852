// import React from 'react'
// import "./Footer.css"

// const Footer = () => {
//   return (
//     <div className='footer'>
//         <p>Peter Zorve (Ph.D.) </p>
//         <ul>
//             <li>peter.zorve@eduzolve.com</li>
//             <li>+358417289032</li>
//         </ul>
//     </div>
//   )
// }

// export default Footer


import React from 'react';
import './Footer.css'; // Optional: You can add custom styles here if you want to use a separate CSS file.

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* <div className="footer-section">
          <h4>About Us</h4>
          <p>
            We are a team dedicated to creating innovative solutions to help you
            learn and grow. Our mission is to provide the best learning
            experience possible.
          </p>
        </div> */}
        
        {/* <div className="footer-section">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div> */}

        {/* <div className="footer-section social-media">
          <h4>Quick Links</h4>
          <a href="#" target="_blank" rel="noopener noreferrer">Home</a>
          <a href="#about" target="_blank" rel="noopener noreferrer">About Us</a>
          <a href="#projects" target="_blank" rel="noopener noreferrer">Projects</a>
        </div> */}
        
        <div className="footer-section">
          <h4>Contact me</h4>
          <p>kegirke@gmail.com</p>

          <br />
          <p>+233 27 527 9145</p>

        </div>
        
        <div className="footer-section social-media">
          <h4>Follow Us</h4>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Dennis Nartey Blemano. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
